<template>
    <div >
         <a title="Activate Selected"
           @click="manipulateUsers('1')"
            data-type="org_user_activate_all"
         >
            <v-icon left>mdi-account</v-icon>
        </a>
        <a title="Deactivate Selected"
           @click="manipulateUsers('2')"
           data-type="org_user_deactivate_all"
        >
            <v-icon left>mdi-account-off-outline</v-icon>
        </a>
    </div>
</template>
<script>
  export default {
    methods:{
      manipulateUsers(status) {
        let selected = [];
        Object.keys(this.additional.selectedRows).forEach(function eachKey(key) {
          selected.push(key);
        });
        if (selected.length > 0) {
          this.$emit('show-warning', false);
          let caller = this;
          this.frameworkAxiosRequest({
            method: 'POST',
            url: 'serve.php?f=administration&f2=organisationUserOverview',
            dataType: 'json',
            data: {
              function: 'controlSelectedUser',
              responseType: 'ajax',
              selected: selected,
              status: status,
            },
          })
              .then(response => {
              //update the header extra
              caller.$set(caller.result, "headerExtraInfo",  response.data.result.headerExtraInfo);
              let currentTable = caller.table;
              let loginUser = response.data.result.login_n_id;
              currentTable.data.forEach((object,i) => {
                selected.forEach((nid) => {
                     if(object.n_id==nid && object.n_id!= loginUser){
                       caller.$set(currentTable.data[i], "n_active", status);
                       caller.$set(caller.selectedRows[nid], "singleRowAttr", { class:"" });
                       if(status==2){//inactive!
                         caller.$set(caller.selectedRows[nid], "singleRowAttr", { class:"grey" });
                       }
                     }
                 });
              });
              caller.$store.commit("tableChanged", currentTable);
              caller.$notify({
                group: "foo",
                text: "Successfully updated",
                duration: 2000,
                type: "success",
                speed: 600,
              });
            })
            .catch(error => {
              console.log(error);
            });
        } else {
          this.$emit('show-warning', true);
        }
      }
     },
    data: function () {
      return {
      };
    },
      props: ["result","additional","table","selectedRows"],

  };
</script>